<template>
  <GModal name="modal" class="modal modal-apply-point">
    <div slot="body">
      <div id="vm-cola-apply-point" class="vm-cola-apply-point">
        <div class="top-container">
          <h1>VERIFICATION</h1>
          <h1>REDEEM SOCO POINT</h1>
        </div>
        <div class="qr-container">
          <div class="qrcode">
            <qrcode-vue :value="QRcode" :size="290" level="H"></qrcode-vue>
          </div>
        </div>
        <div class="bottom-container">
          <p>Scan QR Code di atas untuk menggunakan aplikasi</p>
          <p><b>SOCO by Sociolla</b> atau melalui <b>www.soco.id</b></p>
        </div>
        <div class="cancel-redeem" @click="cancelRedeem">
          <p>Cancel redeem?</p>
        </div>
      </div>
    </div>
  </GModal>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';
import helpers from '@/mixins/helpers';

const Config = require('~/config/default.env').default;
const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'ModalApplyPoint',
  components: {
    QrcodeVue,
  },
  mixins: [helpers],

  data() {
    return {};
  },

  computed: {
    QRcode() {
      return this.$store.state.QRcode.value || '';
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    user_points() {
      return (this.user && this.user.points && this.user.points.count) || 0;
    },
    user_point_list() {
      return this.$store.state.auth.userPointList || [];
    },
    cart_id() {
      return this.$store.state.QRcode.latest_cart_id;
    },
    selected_point() {
      return this.$store.state.QRcode.selected_point;
    },
  },

  created() {
    socket.on('qr_code_login', async (data) => {
      if (this.selected_point && this.cart_id) {
        this.$store.commit('SET_GLOBAL_LOADING', true);
        const res = await this.$store.dispatch('applyPoints', {
          token: data.token,
          point: this.selected_point,
          context: this,
        });
        if (res) {
          setTimeout(() => {
            this.$store.dispatch('fetchCurrentUserCart', this);
            this.$store.commit('SET_GLOBAL_LOADING', false);
            this.$emit('close');
          }, 2000);
        } else {
          this.$store.commit('SET_GLOBAL_LOADING', false);
        }
      }

      socket.off('qr_code_login');
    });
  },

  methods: {
    cancelRedeem() {
      this.$store.commit('SET_SELECTED_POINT', 0);
      this.$store.commit('point_applied', 0);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './apply_point';
</style>
